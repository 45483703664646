
import {Options, setup, Vue} from 'vue-class-component'
import {dashboard} from '@/composables/dashboard'

@Options({
  components: {},
})
export default class Home extends Vue {
  ctx = setup(dashboard)

  mounted(): void {
    this.ctx.fetch()
  }
}
