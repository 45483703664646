import {ref} from '@vue/reactivity'
import StatisticService from '@/services/StatisticService'
import {ResponseData} from '@/services/types'
import {AxiosError} from 'axios'
import {showError} from '@/composables/utils'


interface Statistic {
    agents: {
        total: number
    }

    orders: {
        paid: {
            total: number
        }

        requested: {
            total: number
        }
    }

    balance: {
        total: number
    }
}

const Default = {
    agents: {
        total: 0
    },
    orders: {
        paid: {
            total: 0
        },
        requested: {
            total: 0
        }
    },
    balance: {
        total: 0
    }
}

export function dashboard() {
    const isLoading = ref(false)
    const stat = ref(Default as Statistic)

    function fetch() {
        isLoading.value = true
        StatisticService.general()
            .then((response: ResponseData) => {
                stat.value = response.data.data
            })
            .catch(showError)
            .finally(() => isLoading.value = false)

    }


    return {
        // getters
        isLoading,
        stat,

        // setters

        // actions
        fetch
    }
}